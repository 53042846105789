// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-country-tsx": () => import("./../../../src/templates/country.tsx" /* webpackChunkName: "component---src-templates-country-tsx" */),
  "component---src-templates-hotel-tsx": () => import("./../../../src/templates/hotel.tsx" /* webpackChunkName: "component---src-templates-hotel-tsx" */),
  "component---src-templates-region-tsx": () => import("./../../../src/templates/region.tsx" /* webpackChunkName: "component---src-templates-region-tsx" */),
  "component---src-templates-static-tsx": () => import("./../../../src/templates/static.tsx" /* webpackChunkName: "component---src-templates-static-tsx" */),
  "component---src-templates-tour-tsx": () => import("./../../../src/templates/tour.tsx" /* webpackChunkName: "component---src-templates-tour-tsx" */)
}

